.photos-grid {
  grid-template-columns:repeat(3, minmax(0, 322px));
  display: grid;
  gap: 30px;
}

.photos-grid img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* object-fit: cover; */
}

.photos-grid-item {
  border-radius: 15px;
  height: 280px;
}

.upload-gellery-img {
  text-align: center;
}

.upload-gellery-img .upload-img {
  /* background: rgb(141 141 141 / 20%); */
  width: 85px;
  height: 85px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: auto;
}

.upload-gellery-img h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 15px;
}

.upload-gellery-img p {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  max-width: 247px;
  margin: auto;
  margin-bottom: 14px;
}

.upload-gellery-img button {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
}

.add-photos .photos-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13.605px;
  border: 1px dashed rgba(48, 48, 48, 0.3);
  background: rgba(141, 141, 141, 0.05);
  box-shadow: 0px 0px 1.759px 0px rgba(82, 106, 103, 0.19);
  max-width: 322px;
  height: 229px;
}

.photos-grid-item-added {
  border: none !important;
  position: relative;
}
.photos-grid-item-added:hover .image-buttons {
  display: flex;
}
.product-item:hover .image-buttons {
  display: flex;
}
.image-buttons {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.image-buttons label {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--border);
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.498047 12.3771V15.5021H3.62305L12.8397 6.28542L9.71471 3.16042L0.498047 12.3771ZM15.2564 3.86875C15.5814 3.54375 15.5814 3.01875 15.2564 2.69375L13.3064 0.74375C12.9814 0.41875 12.4564 0.41875 12.1314 0.74375L10.6064 2.26875L13.7314 5.39375L15.2564 3.86875Z' fill='white'/%3E%3C/svg%3E%0A");;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.image-buttons button:hover,
.image-buttons label:hover {
  background-color:var(--main);
}
.image-buttons button {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--border);
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}
.image-buttons .edit_event_pencil button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--border);
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-position: center;
  /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.498047 12.3771V15.5021H3.62305L12.8397 6.28542L9.71471 3.16042L0.498047 12.3771ZM15.2564 3.86875C15.5814 3.54375 15.5814 3.01875 15.2564 2.69375L13.3064 0.74375C12.9814 0.41875 12.4564 0.41875 12.1314 0.74375L10.6064 2.26875L13.7314 5.39375L15.2564 3.86875Z' fill='white'/%3E%3C/svg%3E%0A") !important; */
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.498047 12.3771V15.5021H3.62305L12.8397 6.28542L9.71471 3.16042L0.498047 12.3771ZM15.2564 3.86875C15.5814 3.54375 15.5814 3.01875 15.2564 2.69375L13.3064 0.74375C12.9814 0.41875 12.4564 0.41875 12.1314 0.74375L10.6064 2.26875L13.7314 5.39375L15.2564 3.86875Z' fill='black'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat;
  border: none;
}
.image-buttons .button1 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 15.435V7.47003C1 5.20503 0.999941 4.07002 1.43994 3.20502C1.82908 2.44477 2.44862 1.82699 3.20996 1.44C4.07496 1 5.20997 1 7.46997 1H12.73C14.995 1 16.13 1 16.995 1.44C17.7563 1.82699 18.3759 2.44477 18.765 3.20502C19.205 4.07002 19.205 5.20503 19.205 7.47003V8.70502C19.205 10.97 19.205 12.1 18.765 12.965C18.3759 13.7265 17.7565 14.3459 16.995 14.735C16.13 15.175 14.995 15.175 12.73 15.175H8.45996C8.12543 15.1549 7.7897 15.1751 7.45996 15.235C7.24915 15.2848 7.0481 15.3693 6.86499 15.485C6.59477 15.6828 6.34817 15.911 6.13 16.165L5.18994 17.14C3.88494 18.49 3.22994 19.165 2.68994 19.22C2.45138 19.2455 2.21016 19.2134 1.98657 19.1264C1.76298 19.0394 1.5635 18.9001 1.40491 18.72C0.999907 18.25 1 17.315 1 15.435Z" stroke="url(%23paint0_linear_2594_87234)" stroke-width="1.5" stroke-miterlimit="10"/><defs><linearGradient id="paint0_linear_2594_87234" x1="5.87854" y1="-2.88863" x2="23.21" y2="14.0016" gradientUnits="userSpaceOnUse"><stop stop-color="%23001597"/><stop offset="0.4691" stop-color="%2301104D"/><stop offset="0.8208" stop-color="%23020C1C"/><stop offset="0.9944" stop-color="%23020B09"/></linearGradient></defs></svg>');
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.video_like_bg:hover,
.image-buttons .button1:hover {
  background-color: rgb(235, 235, 236);
}

.add-item-model button.edit-button-model {
  /* width: 35px;
  height: 35px; */
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}
.add-item-model .primary-btn-modelConfirm {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  width: 110px;
  height: 53.6px;
}

.add-item-model .primary-btn-modelConfirm:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.add-item-model .secondary-btn-modelConfirm {
  width: 110px;
  border-radius: 16px;
  border: 1px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  height: 53.6px;
}
.add-item-content .Gallery_header {
  color: #303030;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 23px;
}

.gallery_add_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.crop-btn{
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 4px;
}
button.crop-btn:hover{
  border-radius: 8px;
  background: transparent;
}
button.crop-btn:hover svg{
  fill: #001597;
}

.image_add button {
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 4.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}