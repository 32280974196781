.creator-profile-main {
    /* margin-left: 220px; */
    margin-top: 73px;
    /* padding: 20px 30px; */
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.light-button span {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.light-button {
    background: #fff;
    padding: 14px 22px;
    border-radius: 16px;
    border: 2px solid #001597;
    line-height: normal;
}

.free-courses-starter-pack h5 {
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
}

.free-courses-starter-pack p {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    max-width: 502px;
    margin: 0;
}

.free-courses-starter-pack {
    border-radius: 15px;
    position: relative;
    background: url(../../images/0e5c03efde6b7504226e608a6dbb3eea.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
}

.free-courses-starter-pack .details,
.free-courses-starter-pack button {
    position: relative;
    z-index: 1;
}

.free-courses-starter-pack::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: #303030;
    border-radius: 15px;
}

.feed-container {
    display: flex;
    column-gap: 25px;
}

.feed-right {
    width: 247px;
}

.feed-left {
    width: calc(100% - 272px);
}

.feed-challanges-item {
    height: 191px;
    border-radius: 15px;
    position: relative;
}

.feed-challanges-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(58, 58, 58, 0.00) 45.08%, rgba(58, 58, 58, 0.73) 70%, rgba(22, 22, 22, 0.85) 93.06%);
}

.about-challanges p {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
}

.about-challanges span {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.about-challanges {
    position: absolute;
    bottom: 14px;
    z-index: 1;
    width: calc(100% - 20px);
    left: 10px;
}

.feed-right h3 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
}
.p-20{
    padding: 20px;
}
.mt-20{
    margin-top: 20px;
}
.badge-container{
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 12px;
}
.badge-container img{
    width: 51px;
    height: 51px;
    border-radius: 50%;
    object-fit: cover;
   
}
.badge-item{
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow:0px 0px 15px rgba(82, 106, 103, 0.19);
}
.feed-right button{
    border: none;
    background: transparent;
    color:  #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    margin: 15px 0 40px 0;
}
.creator-profile-main.creator-profile-create{
    margin-top: 0;
    /* padding: 25px 30px 30px 30px; */
}
.creator-profile-create .details-tab-view .react-tabs__tab-list {
    border-radius: 47px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    background: #FAFAFA;
    box-shadow: none;
}

.scroll_bars_install_addOns{
    overflow: auto;
    min-height: 550px;
    padding: 5px 15px 5px 10px;
    height: calc(100vh - 295px);
  }

.sticky_header_install_addOns{
    position: sticky;
    top: 0px; 
    z-index: 1000; 
    padding: 15px 0 15px 0;
    background-color: #fff;
}

.install_addOnd_padding{
    padding-right: 10px !important;
}