.profile-wrapper {
  margin-left: 220px;
  padding: 30px;
  padding-top: 130px;
}
.profile-head {
  height: 411px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}
.profile-banner {
  height: 261px;
  border-radius: 15px;
}
.profile-detail-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px !important;
}
.profile-head-left {
  display: flex;
  column-gap: 18px;
}
.tint-image {
  filter: invert(50%) brightness(500%) contrast(95%);
}
.profile-detail .name {
  color: #303030;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  padding-top: 13px;
}
.profile-detail .tag {
  color: #303030;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}
.profile-count li,
.profile-detail li {
  list-style-type: none;
}
.profile-count,
.profile-detail {
  margin: 0;
  padding: 0;
}
.profile-count {
  display: flex;
  column-gap: 60px;
  padding-top: 23px;
}
.profile-count svg {
  margin-right: 13px;
}
.profile-count li {
  color: #303030;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
.profile-button-group {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.primary-btn {
  border-radius: 100px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  ) !important;
  -webkit-text-fill-color: #fff;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  border: none;
  padding: 6px 15px;
  border: 1px solid transparent;
  transition: all 0.6s ease;
}
.primary-btn-no-hover {
  border-radius: 22px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  ) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: none;
  padding: 14px 22px;
  border: 1px solid transparent;
  transition: all 0.6s ease;
}
.primary-btn:hover {
  border-radius: 22px;
  background: #fff !important;
  -webkit-text-fill-color: #001597;
  border: 1px solid #001597;
}
.primary-btn:hover img{
  filter: brightness(0) saturate(100%) invert(7%) sepia(86%) saturate(6551%) hue-rotate(237deg) brightness(90%) contrast(112%);
}
.secondary-btn {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: #303030;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: none;
  padding: 14px 22px;
  border-radius: 30px;
}
.secondary-btn:hover {
  background: linear-gradient(
    294.96deg,
    rgba(0, 21, 151, 0.1) 10.62%,
    rgba(1, 16, 77, 0.1) 52.16%,
    rgba(2, 12, 28, 0.1) 83.31%,
    rgba(2, 11, 9, 0.1) 98.68%
  );
}
.profile-button-group button svg {
  margin-right: 10px;
}
.team-member img {
  width: 120px;
  height: 120px;
  border-radius: 35px;
  border: 2.705px solid #001597;
  box-shadow: 0px 0px 5.41px 0px rgba(0, 0, 0, 0.15);
}
.team-member p {
  color: #303030;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  margin-bottom: 0;
}
.member-name {
  padding: 15px 0 20px 0;
}
.member-name p {
  color: #8d8d8d;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
}
.member-name p span {
  color: #303030;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.team-member {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 30px;
}
.team-memver-card {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
  text-align: center;
}
.grid-gellery {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 19px;
}
.gellery-item {
  border-radius: 15px;
  height: 244px;
  position: relative;
}
.gellery-item img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.grid-1 {
  grid-row: 1 / span 2;
  height: 507px;
}
.gellery-item h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
  margin-bottom: 0;
}
.overlay-gredient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(4, 4, 4, 0.77) 11.41%,
    rgba(189, 189, 189, 0) 117.66%
  );
  top: 0;
  left: 0;
  border-radius: 15px;
}
.discription-box p {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.discription-box,
.facilities-gellery,
.our-team {
  padding: 30px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}
.profile-wrapper h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.video-wall {
  height: 453px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.video-wall .overlay {
  border-radius: 15px;
  opacity: 0.6;
  background: #303030;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video-wall .video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
}
.discription-box {
  margin: 30px 0 60px 0;
}
.our-team {
  margin-top: 40px;
}
.navigation {
  border-radius: 15px 15px 0px 0px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.navigation ul li {
  list-style-type: none;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  position: relative;
  z-index: -6;
}
.navigation ul li a {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.navigation ul li svg {
  margin-right: 8px;
}
.navigation ul {
  margin: 0;
  display: flex;
  column-gap: 30px;
  padding: 23px 30px;
}

.profiles-section {
  margin-left: 220px;
  padding-top: 93px !important;
  padding: 30px;
}
.profiles-section h1 {
  color: #303030;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.profiles-section .dropdown {
  margin: 0;
}
.profiles-section .primary-btn {
  padding: 12px 24px;
}
.primary-btn img {
  margin-right: 10px;
}
.profiles-section .primary-button {
  border-radius: 16px;
  border: 1px solid #001597;
  background: transparent;
  padding: 11px 19px;
  width: auto;
}
.profiles-section .primary-button span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
.custome-badge {
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 12px 12px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.badge-pending {
  background: var(
    --Gradient-revised,
    linear-gradient(
      134deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    )
  );
}
.badge-online {
  background: linear-gradient(324deg, #04771c 17.31%, #65ba4a 80.18%);
}
.btn-white {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 11px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: none;
  color: #303030;
  font-size: 12px;
  font-weight: 600;
  border: 1.8px solid transparent;
}
.btn-white:hover {
  transform: scale(0.9);
}
.btn-white .edit_profile_text {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.btn-white img {
  width: 16px;
  height: 16px;
}
.profiles-section .btn-white {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 12px 16px;
  border-radius: 30px;
}
.profiles-section .add-ons-main-ing {
  padding: 78px 6px 30px 6px;
  height: 265px;
}
.profiles-section-sub {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
}
.profiles-section-sub-member {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
}
.collapse-button button {
  border: none;
  background: transparent;
}
.profiles-btn {
  margin-bottom: 20px;
}

.add-ons .add-ons-detail .secondary-btn-model {
  border-radius: 16px;
  border: 1.8px solid #00298f5e;
  background: #fff;
  padding: 7px 11px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
}
.add-ons .add-ons-detail .secondary-btn-model:hover {
  background-color: rgb(235, 235, 236);
}
.add-ons .add-ons-detail .secondary-btn-model:hover span {
  background-color: #001597;
}

.secondary-btn-model:disabled,
button[disabled] {
  opacity: 60%;
  pointer-events: none;
}

.secondary-btn-model:disabled,
button[disabled] {
  opacity: 60%;
  pointer-events: none;
}

.profile-type-dropdown {
  width: 175px;
  border-radius: 24px;
}

.profile-type-dropdown .dropdown__text {
  font-size: 16px;
  font-weight: 500;
  -webkit-text-fill-color: #01104d;
}
.profile-type-dropdown {
  padding: 12px 24px !important;
}
@media only screen and (max-width: 1200px) {
  .step-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


.fyredate_bg{
  background: linear-gradient(150.01deg, #EB3469 3.43%, #EB3366 6.23%, #EB3335 65.83%, #EB3323 95.63%);
}
.aToz_bg {
  background: linear-gradient(150.01deg, #00CEA1 6.08%, #02A289 62.68%, #038F7F 93.43%);
}
.profile_grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 267px));
  gap: 28px;
}
@media screen and (max-width:1200px) {
  .profile_grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 267px));
    gap: 28px;
  }
}
