table{
    width: 100%;
}

.discount_list{
position: relative;
}
  .image-buttons-discount {
    display: flex;
    column-gap: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .image-buttons-discount label {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .image-buttons-discount button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: none;
  }

  .statistic-chart-container .react-datepicker__input-container .custom-datepicker{
    width:100%;
  }
  .calendar .react-datepicker__input-container::before {
    content : "" !important;
   
  }

  .discount_image_size {
    margin: 0 !important;
  }

  .discount_image_size .image_view img{
    height: 230px !important;
  }

  .discount_image_size .upload-div{
    margin: 0px !important;
  }
  .discount_image_size .image_view{
    overflow-x: visible !important;
  }

  .add-item-model .discount-uploader button.secondary-btn-model.upload_btn_width {
    width: auto !important;
    background: linear-gradient(134.3deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%),
linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
border-radius: 999px;
gap: 10px;
margin-top: 35px;
  }
  .add-item-model .discount-uploader button.secondary-btn-model.upload_btn_width span {
    -webkit-text-fill-color: #fff;
  }
  .discount_list th .ant-table-column-title{
    color: #8D8D8D;
    font-weight: 600;
    font-size: 16px;
  }
  .discount_list th.ant-table-cell{
    color: #8D8D8D !important;
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .discount_list td.ant-table-cell {
    color: #505050 !important;
}

.discount-date-input .react-datepicker__input-container input {
  border-radius: 999px;
  border: none;
  background: #FAFAFA;
  width: 100%;
  padding: 0px 20px;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: start;
  height: 50px;
}