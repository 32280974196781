.custom-model-overlay {
  background: #30303033;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 99;
}

.success-modals {
  border-radius: 0px 15px 15px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 11.932px 0px rgba(0, 0, 0, 0.03);
  width: 600px;
  margin-right: 2.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
