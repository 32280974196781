.add_room_text h3 {
  font-family: Montserrat;
  font-size: 24px;
  font-style: italic;
  font-weight: 900;
  line-height: 29.26px;
  text-align: center;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding-right: 2px;
}
.add_room_text p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #303030;
}
.add_room_text img {
  text-align: center;
  justify-content: center;
}
.add_room_text .add-video-rows {
  text-align: center;
  justify-content: center;
}

.add_room_modal {
  padding: 20px 20px;
}

.add-item-model .add_room_modal.add-item-content {
  max-width: 800px;
  width: 100%;
  max-height: 700px;
  height: auto;
  /* padding-right: 20px; */
}

.item-details-input .room_image img {
  width: 100%;
  height: 250px;
}

.room_image img {
  border-radius: 10px;
}
.room-card-img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  aspect-ratio: 64/27;
  object-fit: cover;
  object-position: center;
}
.deal-label label,
.detail-grid label {
  color: rgba(11, 10, 10, 1);
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 0;
}
.deal-label p {
  font-size: 14px;
  margin-bottom: 0;
  /* background: rgba(231, 239, 255, 1);
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
  text-align: left; */
}
.detail-grid p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(90, 90, 90, 1);
  text-align: left;
}

.amenities_name .facility_name {
  position: relative;
}

.amenities_name .facility_name button {
  padding: 0;
  border: none;
  background: transparent;
  position: absolute;
  top: -11px;
  right: -4px;
  z-index: 1;
  /* display: none; */
}
.amenities_name label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 104px;
  border-radius: 15px;
  background: #fff;
  padding: 0;
  cursor: pointer;
}

.amenities_name .plsimg {
  width: 175px;
  height: 128.8px;
  row-gap: 13px;
  border: 1px solid #30303033;
}

.add-amenity-btn{
  border: none;
}
.room_add_img{
  margin-bottom: 0px !important;
  /* border: 1px solid #30303033 !important; */
}

.local_dropdown_room .dropdown__text {
  -webkit-text-fill-color: #a0a0a0 !important;
  background: none !important;
  font-size: 14px !important;
}
/* .local_dropdown_room .dropdown__text.selected {
  -webkit-text-fill-color: #303030 !important;
} */
.selected_class_local_room .dropdown__text {
  -webkit-text-fill-color: rgba(11, 10, 10, 1) !important;
}

.add-item-content .dropdown{
  border: none;
  background: #FAFAFA;
  border-radius: 999px;
  width: 100%;
  padding: 15px 15px;
}

.local_dropdown_room .dropdown__items{
  outline: 1px solid rgba(225, 226, 229, 1) !important;
}
.dropdown.active.selected_class_local_room .dropdown__items{
  outline: 1px solid rgba(225, 226, 229, 1) !important;
}

.add_room_modal .dropdown.active:before {
  background: none !important;
}

.room_show_label{
  color: #5A5A5A !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 0 !important;
}