.story-slider-item,
.slider-story {
  border-radius: 15px;
  position: relative;
  height: 286px;
  width: 174px;
}

.swiper-slide {
  width: 150px;
}

.mySwiper-story .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='rotate(180 20 20)' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M23.5979 10.7347L14.3652 20.4928L23.5979 30.2566' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.mySwiper-story .swiper-button-next {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M16.4021 29.2653L25.6348 19.5072L16.4021 9.74341' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.story-slider-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(48, 48, 48, 0) 60.94%,
    rgba(48, 48, 48, 0.88) 90.83%
  );
}

.story-culture-img {
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 3px solid #001597;
  border-radius: 25px;
}

.culture-name {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.story-culture {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.mySwiper-story .swiper-button-next.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='matrix(1 0 0 -1 0 40)' fill='%238D8D8D' stroke='%238D8D8D' stroke-width='0.625'/%3E%3Cpath d='M16.4021 10.7347L25.6348 20.4928L16.4021 30.2566' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.mySwiper-story .swiper-button-prev.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='matrix(-1 0 0 1 40 0)' fill='%238D8D8D' stroke='%238D8D8D' stroke-width='0.625'/%3E%3Cpath d='M23.5979 29.2653L14.3652 19.5072L23.5979 9.74341' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.stories-component h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 23px;
}

.add-stories .story-slider-item::after {
  display: none;
}

.add-stories .story-slider-item,
.add-stories .slider-story {
  border: 1px solid rgba(48, 48, 48, 0.2);
}

.add-story-btn {
  border: none;
  background: transparent;
  display: block;
  text-align: center;
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.add-story-btn img {
  display: block;
  margin: auto;
  margin-bottom: 14px;
}
.add-stories .story-slider-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.edit-story-btn {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-size: unset;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-color: #fff;
  position: absolute;
  top: -17px;
  right: -17px;
  cursor: pointer;
  display: none;
}
.story-slider-item:hover .delete-btn {
  display: block;
}
.delete-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.story-item {
  border-radius: 10px;
  border: 1px solid #e1e2e5;
  background: #fafafa;
  box-shadow: none;
  height: 200px;
  width: 140px;
}

.story_text {
  text-align: center;
  margin-top: 55%;
  margin-bottom: 0px !important;
  color: #303030;
  font-size: 16px !important;
  font-weight: 500;
  line-height: normal;
}

.story_hight {
  height: 431px;
  background-color: #e3e3e3;
  width: 244px;
  border-radius: 14px;
}
.story_hight video{
  height: 431px;
}

.story-slider-item {
  width: 140px;
  height: 200px; /* Adjust height as needed */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.story-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0; 
  transition: opacity 0.3s ease-in-out;
}

.story-slider-item:hover .story-video {
  opacity: 1; 
}

.story_play_icon {
  position: absolute;
  height: 45px;
  width: 45px;
  top: 47%;
  left: 20%;
}
