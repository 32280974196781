.dashboard{
    margin-left: 220px;
    margin-top: 100px;
}
.dashboard-wrapper{
    padding: 30px;
}
.counter-main{
    display: grid;
    grid-template-columns:repeat(4, minmax(0, 1fr));
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 25px;
    column-gap: 29px;
}
.counter-item{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 33px 0 30px 28px;
}
.counter-item p{
    color:  #303030;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 31px 0 10px 0;
}
.counter-item .counter{
    color:  #303030;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    position: relative;
    display: flex;
    column-gap: 9px;
    padding: 0;
}
.counter-item .counter span{
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
}
.list-item p,.activity-date{
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.list-item p{
    margin-bottom: 18px;
}
.list-item .name{
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.list-item .discription{
    color: #303030;
}
.primary-btns {
    border-radius: 50px;
    background: #fff;
    display: flex;
    max-width: 400px;
    width: 100%;
    padding: 14px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #001597;
    background: linear-gradient(
      170.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  }
  .primary-btns span {
    text-align: right;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 19.5px;
    background: linear-gradient(
      134deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    font-style: italic;
  }
  .primary-btns:hover {
    background: linear-gradient(
      294.96deg,
      #001597 10.62%,
      #01104d 52.16%,
      #020c1c 83.31%,
      #020b09 98.68%
    );
  }
  .primary-btns:hover span {
    -webkit-text-fill-color: #fff;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
  }
  .primary-btns:focus,
  .primary-btns:focus-visible,
  .primary-btns:visited {
    background: linear-gradient(
      134deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  }
  .primary-btns:focus a,
  .primary-btns:focus-visible a,
  .primary-btns:visited a {
    -webkit-text-fill-color: #fff;
  }
  .primary-btns:disabled {
    background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
    opacity: 40% !important;
  }
  .primary-btns a {
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(
      134deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
  }
  .primary-btns:disabled span {
    -webkit-text-fill-color: #ffffff;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
  }
  .primary-btns:focus span,
  .primary-btns:focus-visible span,
  .primary-btns:visited span {
    -webkit-text-fill-color: #fff;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
  }

.list-btn-row{
    display: flex;
    column-gap: 15px;
}
.activity-details{
    display: flex;
    align-items: flex-start;
    column-gap: 9px;
}
.list-item{
    display: grid;
    grid-template-columns: auto auto;
    border-bottom:1px solid rgb(141 141 141 / 40%);
    padding: 20px 0;
}
.list-item:last-child{
    border-bottom: none;
}
.activity-detail-img img{
    width: 37px;
    height: 37px;
    border-radius: 50%;
}
.activity-details ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.activity-details ul .designation{
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.activity-details ul .designation-sub{
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.campaingns-details li{
    color:  #303030;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}
.line-yellow{
    border-radius: 3px;
    background:  linear-gradient(180deg, #FFD739 0%, #F8C600 99.99%, #FFD739 100%);
    width: 77.546px;
    height: 9px;
    margin-top: 6px;
}
.line-blue{
    border-radius: 3px;
    background:  #001C93;
    width: 77.546px;
    height: 9px;
    margin-top: 6px;
}
.campaings-banner{
    background-repeat: no-repeat;
    background-size: cover;
}
.campaings-banner .campaings-title{
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.campaings-status{
    color: #001C93;
    font-size: 14px ;
    font-weight: 600 ;
    line-height: normal;
    border-radius: 15px;
    background: #D8DBFF;
    padding: 8px 10px;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    
}
.campaigns-sub{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
}
.campaings-item{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.campaings-item .campaings-banner{
    height: 145px;
    border-radius: 15px;
    position: relative;
}
.campaings-item .campaings-banner::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(67, 67, 67, 0.00) 9.93%, rgba(17, 17, 17, 0.44) 74.13%);
}
.campaings-item .campaings-banner .campaings-title{
    position: absolute;
    bottom: 12px;
    left: 15px;
    margin: 0;
    z-index: 1;
}
.campaingns-details ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.campaingns-details{
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
}
.campaingns-details ul li:first-child{
    padding-bottom: 10px;
}

.video-items-row{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 11px;
}
.video-item{
    border-radius: 15px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.video-cover{
    height: 145px;
    border-radius: 15px;
    position: relative;
}
.video-cover::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(0deg, rgba(67, 67, 67, 0.45) 0%, rgba(67, 67, 67, 0.45) 100%);
}
.video-details h6{
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    background:linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    display: flex;
    align-items: center;
    width: 75%;
}
.video-1 h6 .health_icon{
    border-radius: 6.448px !important;
    box-shadow: none !important;
    height: 16px;
    width: 14px;
}
.video-details h6 span{
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.rating{
    border-radius: 20px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    width: 37px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
}
.rating span{
    font-size: 9.529px;
    font-weight: 500;
    line-height: normal;
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    width: 32.82px;
    height: 11.57px;
    -webkit-text-fill-color: transparent;
    
}
.rating img{
   text-align: center;
   align-items: center;
   justify-content: center;
   margin-left: 9px;
}
.video_time{
    border-top-left-radius: 9.74px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    width: 47px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
}
.video_time img{
   text-align: center;
   align-items: center;
   justify-content: center;
   margin-left: 9px;
}
.video-details p{
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 13px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    margin-top: 10px;

}
.video-details .other-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.video-details .other-info span{
    color:  #8D8D8D;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 6px;
}
.video-cover .time-duration{
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    border-radius: 7px 0px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    padding: 5px 10px;
    margin: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}
.video-cover .like{
    background: #fff;
    height: 19px;
    width: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    border-radius: 6px;
    top: 11px;
    left: 11px;
}
.podcast-items-row{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 11px;
}
.podacast-item{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 424px;
}
.podacast-items{
    border-radius: 15px;
    background: #FFF;
    border: 0.5px solid var(--secondary-border);
    /* height: 336px; */
    height: fit-content;
}
 .podcast-cover {
    aspect-ratio: 1 / 1;
    width: 100%;
    border-radius: 15px;
    position: relative;
}
/* .podcast-cover::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    border-radius: 15px;
    background: #E0E0E0;
} */
.drop-button{
    background: transparent;
    border: none;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 15px;
}
.drop-button svg{
    display: block;
}
.play{
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
}
.letest-content h5{
    color:  #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.letest-content a{
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}
.letest-content-item,.letest-content{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 25px;
}
.letest-content{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.insights td{
    padding: 25px 0 10px 0;
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
.insights th{
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(141 141 141 / 50%);
}
.td-link{
    background:  linear-gradient(150deg, #03CDFD 6.08%, #03B9FD 23.98%, #0188FD 70.39%, #0175FD 93.43%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
border-bottom: 1px solid #03cdfd;
}
.tab-dashboard .react-tabs__tab{
    color:  #8D8D8D;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    border: none;
}
.tab-dashboard .react-tabs__tab svg{
    margin-right: 10px;
}
.tab-dashboard .react-tabs__tab--selected svg path{
    fill: #001C93;
}
.tab-dashboard .react-tabs__tab--selected{
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.tab-dashboard .react-tabs__tab-list{
    border-bottom: none;
}
.activity .dropdown{
    margin: 0px 0 0px auto;
}
.tab-dashboard .activity{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px 30px 30px 30px;
}
.insight-table{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 30px;
}
.gbn-business-profile{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
    margin-bottom: 30px;
}
.business-profile-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.business-profile-logo span{
    color:  #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-left: 16px;
}
.gbn-business-profile .profile-img{
    width: 78px;
    height: 78px;
    border-radius: 30px;
    border: 2px solid #001597;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    margin: auto;
    margin-top: 3px;
}
.gbn-business-profile h3{
    color:  #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    padding-top: 25px;
}
.gbn-business-profile .profile-detail{
    color:  #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    padding: 5px 0;
}
.follower-creator ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    column-gap: 25px;
    justify-content: center;
}
.follower-creator li{
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.blue-card-detail .name{
    color:  #303030;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}
.blue-card-detail .credit{
    color:  #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}
.blue-card-detail{
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}
.blue-card-detail img {
    width: 31%;
}
.follower-creator{
    padding: 11px 0 35px 0;
}
.gbn-business-profile .status{
    height: 13px;
    width: 13px;
    background: #00FF00;
    display: inline-block;
    border-radius: 50%;
}
.tasks-profile{
    width: 36px;
    height: 36px;
    border-radius: 14px;
    border: 1.308px solid  #001597;
    box-shadow: 0px 0px 3.487px 0px rgba(0, 0, 0, 0.15);
}
.tasks-list-item{
    display: flex;
    column-gap: 10px;
    border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
padding: 10px;
}
.tasks-list-item h5{
    color:  #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.tasks-list-item p{
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 5px 0 8px 0;
}
.tasks-list-item ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    column-gap: 20px;
}
.tasks-list-item li{
    color:  #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
.tasks-list-item li span{
    color: #303030;
}
.tasks{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
}
.tasks .react-datepicker{
    border: none;
    box-shadow: none;
}
.about-tasks {
    width: calc(100% - 46px);
}
.tasls-list{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.tasks-celender{
    padding: 30px 0;
}
.tasks-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tasks-head h3{
    color:  #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.tasks-head span{
    color:  #8D8D8D;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}
.apexcharts-menu-icon {
    display: none !important;
  }
  .apexcharts-xaxistooltip.apexcharts-active{
    opacity: 0 !important;
  }  
  .apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%) !important;
    color: #fff;
}
.chart-tooltip-data{
    padding: 12px;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    border-bottom: none !important;
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%) !important;
}
.this-month{
    color: rgba(255, 255, 255, 0.80);
    font-size: 6.841px;
    font-weight: 600;
}
.chart-data{
    color: #FFF;
    font-size: 18.814px;
    font-weight: 600;
    line-height: normal;
}
.month-name{
    color: rgba(255, 255, 255, 0.80);
    font-size: 10.262px;
    font-weight: 600;
    line-height: normal;
}
.custom-apex-chart .apexcharts-canvas .apexcharts-series-canvas circle:hover {
    fill: red !important; /* Change this to the color you want on hover */
  }
  circle{
    fill: #001597 !important;
    stroke: #001597 !important;
  }
  .chart{
    border-radius: 14.168px;
    background: #FFF;
    box-shadow: 0px 0px 14.168px 0px rgba(0, 28, 147, 0.13);
    padding: 20px 27px;
  }
  #chart .dropdown{
    margin: 0;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  }
  #chart .dropdown .dropdown__text{
    -webkit-text-fill-color:#fff
  }
  #chart .dropdown .dropdown__text:after{
    border-color: #fff;
  }
  .previous-month-button{
    background: transparent;
    border: none;
    color: #D0D1D2;
    font-size: 10.725px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    column-gap: 9px;
    align-items: center;
    width: 100%;
  }
  .previous-month-button span{
    width: 54.521px;
    height: 2px;
    background: #ECE9F1;
    border-radius: 2px;
    display: inline-block;
  }
  .users-interactions{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 30px;
  }
  .users-interactions h3{
    color:  #11263C;
    font-size: 17.022px;
    font-weight: 600;
    line-height: normal;
  }
  .chart-progress p{
    color: #11263C;
    font-size: 28.371px;
    font-weight: 600;
    line-height: normal;
    opacity: 1;
    margin: 50px 0 27px 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .chart-progress p span{
    color: #303030;
    font-size: 13.24px;
    font-weight: 600;
    line-height: normal;
    opacity: 0.3;
  }
  .online-user{
    color:  #11263C;
    font-size: 13.24px;
    font-weight: 600;
    line-height: normal;
  }
  .online-user span{
    color: #303030;
    font-size: 13.24px;
    font-weight: 600;
    line-height: normal;
    opacity: 0.3;
    display: block;
  }
  .online-user-sign,.offline-user-sign{
    width: 28px;
    height: 3px;
    border-radius: 94px;
    display: block;
    margin-top: 8px;
  }
  .online-user-sign{
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
  }
  .offline-user-sign{
    background: rgba(255, 0, 49, 0.15);
  }
  .chart-progress ul{
    list-style-type: none;
    margin: 0;
    padding: 15px 0 0 0;
    display: flex;
    column-gap: 47px;
  }
  .chart-progress ul li{
    display: flex;
    column-gap: 15px;
  }
.dashbord-left-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.dashboard-right{
    width: 390px;
    position: sticky;
    top: 90px;
    height: calc(100vh - 110px);
    overflow: auto;
    scroll-behavior: smooth;
    padding: 10px;
    margin: -10px;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
.dashboard-right::-webkit-scrollbar {
    display: none;
}
.dashboard-left{
    width: calc(100% - 383px);
}
.dashboard-main{
    display: flex;
    column-gap: 30px;
}
.react-tabs__tab-list{
    /* margin-bottom: 23px !important; */
}

.insights{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: transparent !important;
}
.tab-dashboard .react-tabs__tab-list{
    position: sticky;
    top: 100px;
    background: #fff;
    z-index: 9;
}
.chart .react-datepicker-wrapper input{
    border-radius: 3.575px;
    border: 0.894px solid  #ECE9F1;
    background:  #FFF;
    color:  #11263C;
    font-size: 12.513px;
    font-weight: 600;
    line-height: normal;
    padding: 3px 10px;
    width: 100%;
}
.chart .react-datepicker-wrapper .react-datepicker__input-container::after{
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='13' viewBox='0 0 14 13' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.34216 3.21462C2.34216 2.63873 2.80902 2.17188 3.38491 2.17188H10.6841C11.26 2.17188 11.7269 2.63873 11.7269 3.21462V10.5138C11.7269 11.0897 11.26 11.5566 10.6841 11.5566H3.38491C2.80902 11.5566 2.34216 11.0897 2.34216 10.5138V3.21462Z' stroke='%23422F8A' stroke-opacity='0.87' stroke-width='1.04274' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.12 1.12891V3.21439' stroke='%23422F8A' stroke-opacity='0.87' stroke-width='1.04274' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.9491 1.12891V3.21439' stroke='%23422F8A' stroke-opacity='0.87' stroke-width='1.04274' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.34216 5.30029H11.7269' stroke='%23422F8A' stroke-opacity='0.87' stroke-width='1.04274' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: cover;
    background-position: center;
    width: 12.5px;
    height: 12.5px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 9px;
}
.chart .react-datepicker-wrapper .react-datepicker__input-container{
    position: relative;
}
.monthpicker{
    display: flex;
    align-items: center;
    column-gap: 26px;
}
.monthpicker .react-datepicker__month-text--keyboard-selected{
    color: #fff;
}
.chart-wrapper{
    row-gap: 10px;
}
.profile-rating img{
    width: 75px;
    height: 12px;
}
@media only screen and (max-width: 1370px) {
    .counter-item {
        padding: 10px 0 10px 10px;
    }
    .counter-main{
        column-gap: 10px;
    }
    .counter-item p{
        font-size: 12px;
    }
  }
  @media only screen and (max-width: 1245px) {
    .campaigns-sub {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .video-items-row {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .podcast-items-row {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media only screen and (max-width: 1200px) {
    .counter-item {
        padding: 33px 0 30px 28px;
    }
    .counter-main{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 29px;
        row-gap: 29px;
    }
    .counter-item p{
        font-size: 14px;
    }
  }
  @media only screen and (max-width: 1164px) {
    .tab-dashboard .react-tabs__tab{
        font-size: 12px;
    }
    .tab-dashboard .react-tabs__tab{
        padding: 6px 8px;
    }
  }
  @media only screen and (max-width: 1130px) {
    
  }
  .react-tabs__tab.disabled {
    color: gray;
    cursor: not-allowed;
    pointer-events: none; 
  }
  
  .react-tabs__tab.disabled:focus {
    outline: none;
  }
  
  .react-tabs__tab-panel.disabled {
    display: none; 
  }

.dashboard-filter .dropdown__text{
    font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(134deg, #001597 3.03%, #01104d 47.1%, #020c1c 80.14%, #020b09 96.45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center !important;
  column-gap: 5px !important;
  -webkit-text-fill-color: #01104d;
}

.dashboard-filter {
    padding: 14px 22px !important;
  }
.dashboard-filter{
    width: auto !important;
}

.dashboard_first_skeleton{
    display: flex !important;
}

.gradient_bg {
    background: linear-gradient(
      134deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  }