.add-comment.reply_input input::placeholder{
    margin-left: 20px !important;
}



.add-comment-discussion {
    margin-top: 10px;
    /* padding: 5px; */
    border-radius: 5px;
    position: relative;
    
  }
  
  .add-comment-discussion input[type="text"] {
    width: 100%;
    /* padding-right: 34px; */
    /* margin-right: 5px; */
    border: none;
    border-bottom: 1px solid #001597;
    color: #001597;
    border-radius: 25px;
  }
  
  .add-comment-discussion button {
    background-color: transparent;
    color: #001597;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    z-index: 1;
    right: 0;
  }