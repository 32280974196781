.feeds-video {
  height: 348px;
  border-radius: 15px;
  position: relative;
}

.feeds-video::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 15px;
}

.feeds-main .play {
  width: 58px;
  height: 58px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feeds-main .play img {
  width: 100%;
}

.feeds-profile-main-profile {
  width: 48px;
  height: 48px;
  position: relative;
  padding: 1.8px;
  z-index: 1;
  border-radius: 60px;
  background: linear-gradient(to right, #FFFFFF 5.47%, #C7D5E0 118.05%);
  background-clip: border-box;
  -webkit-background-clip: padding-box;
}

.feeds-profile-main {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.feeds-profile-main ul {
  padding: 0;
  margin: 0;
}

.feeds-profile-main ul li {
  background: #151515;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  list-style-type: none;
}

.feeds-profile-main ul li.designation {
  opacity: 0.5;
  font-weight: 600;
}

.feeds-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feeds-detail h6 {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;
}

.feeds-detail p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  max-width: 426px;
}

.feed-btn-group {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.feeds-button button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feeds-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.save-btn{
  width: 76px;
}
.feeds-video {
  margin: 20px 0;
}
.report-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the modal is on top */
}

.report-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-btn img {
  width: 20px;
  height: 20px;
}

.open_report {
  cursor: pointer;
}

.add-item-model .feed-report {
  background: #fff;
  border-radius: 15px;
  /* padding: 40px; */
  width: 350px !important;
  height: 500px !important;
  overflow-y: scroll;
}

.table_data {
  display: flex;
  max-width: 320px;
}

.table_data span {
  background: #01104d;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;
  cursor: pointer;
}

.table_list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_icons img {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 40%;
}

.add-item-content .feed-scroll{
  padding-bottom: 0%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; 
}

.comment-section.loading {
  height: 130px; 
  display: flex;
  justify-content: center;
  align-items: center;
}


.no-comments-message {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  padding: 10px;
}

.feed-section {
  position: relative;
  height: 100% !important;
}

.feed-section .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; 
}

.add-comment .comment_add_btn{
  margin-right: 8px;
}


.calendar.feed_calender .react-datepicker-popper{
  z-index: 999999 !important;
}
.calendar-clock::before{
  content: "";
  background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4893 0.0839844C4.73926 0.0839844 0.0830078 4.75065 0.0830078 10.5007C0.0830078 16.2507 4.73926 20.9173 10.4893 20.9173C16.2497 20.9173 20.9163 16.2507 20.9163 10.5007C20.9163 4.75065 16.2497 0.0839844 10.4893 0.0839844ZM10.4997 18.834C5.89551 18.834 2.16634 15.1048 2.16634 10.5007C2.16634 5.89648 5.89551 2.16732 10.4997 2.16732C15.1038 2.16732 18.833 5.89648 18.833 10.5007C18.833 15.1048 15.1038 18.834 10.4997 18.834ZM11.0205 5.29232H9.45801V11.5423L14.9268 14.8236L15.708 13.5423L11.0205 10.7611V5.29232Z' fill='url(%23paint0_linear_20530_32529)'/%3E%3Cpath d='M10.4893 0.0839844C4.73926 0.0839844 0.0830078 4.75065 0.0830078 10.5007C0.0830078 16.2507 4.73926 20.9173 10.4893 20.9173C16.2497 20.9173 20.9163 16.2507 20.9163 10.5007C20.9163 4.75065 16.2497 0.0839844 10.4893 0.0839844ZM10.4997 18.834C5.89551 18.834 2.16634 15.1048 2.16634 10.5007C2.16634 5.89648 5.89551 2.16732 10.4997 2.16732C15.1038 2.16732 18.833 5.89648 18.833 10.5007C18.833 15.1048 15.1038 18.834 10.4997 18.834ZM11.0205 5.29232H9.45801V11.5423L14.9268 14.8236L15.708 13.5423L11.0205 10.7611V5.29232Z' fill='black' fill-opacity='0.2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_20530_32529' x1='5.6659' y1='-4.36032' x2='25.4746' y2='14.9691' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translate(17px,-50%);
  z-index: 8;
}
.calendar-clock{
  position: relative;
}
.calendar-clock input{
  padding-left: 45px !important;
}

.calendar-clock.feed_section .react-datepicker-popper{
  transform: none !important;
}
