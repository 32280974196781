.link_dropdown .dropdown__item {
  padding: 10px 18px !important;
}

.add-item-content .drp  .dropdown__items {
  margin-top: 17px;
}

.link_dropdown .dropdown__item_active{
  background: none !important;
}
.link_dropdown .dropdown__item:hover{
  background: rgba(0, 0, 0, 0.05) !important;
}
.link_dropdown .dropdown.active.link_dropdown:before{
  background: none !important;
}
.drp .dropdown {
  background: #FAFAFA;
  border: 1.8px solid #00298fc4 !important;
  border-radius: 10px;
  width: auto;
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
}

.description-input {
  padding: 15px 15px !important;
}

.edit_profile_header{
    position: sticky;
    top: 0px;
    z-index: 1;
    padding: 25px 15px 10px 0;
    background-color: #ffff;
}
.edit_profile_button{
    position: sticky;
    bottom: 0px;
    z-index: 1;
    padding: 10px 15px 15px 0;
    background-color: #ffff;
}

.edit_profile_dropdown{
  padding: 10px !important;
  width: 91px !important;
  border-radius: 15px !important;
}

.edit_link_input{
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}