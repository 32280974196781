.store-profile .mySwiper-story .swiper-button-prev {
    left: -20px;
}

.store-profile .mySwiper-story .swiper-button-next {
    right: -20px;
}

.product-grid--item {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    gap: 18px;
}

.product-item-cover {
    /* height: 230px; */
    border-radius: 15px;
    position: relative;
}

.product-item-cover .rating {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 47px;
    height: 22px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    border-radius: 15px 0;
}

.product-item-cover .rating span {
    -webkit-text-fill-color: #fff;
}

.product-grid-item {
    height: 285px;
    border-radius: 15px;
    background: #FFF;
    /* box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19); */
    box-shadow: 0px 4px 15px 0px #526A6730;

}

.product-item-details span {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.product-item-details {
    padding: 10px 10px 12px 10px;
}

.product-item-details h4 {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.store-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    gap: 18px;
}

.store-container .product-item-cover {
    height: 184px;
}

.store-container .actual-price {
    -webkit-text-fill-color: #8D8D8D;
    text-decoration: line-through;
}

.store-slider .slider--local-image {
    clip-path: none
}

.store-container .like {
    background: #fff;
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    top: 20px;
    left: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product--detail ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: max-content;
}

.product--detail ul li {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    column-gap: 24px;
    row-gap: 15px;
}

.product--detail li {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.product--detail li p {
    color: #303030;
}

.product--detail li span {
    color: #8D8D8D;
}

.product--detail-item {
    display: flex;
    column-gap: 137px;
}

.linier-text {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 22px;
}

.store-product-preview .product-img-selected {
    width: 443px;
    height: 492px;
}
.store-product-preview .product-img-selected img,.store-product-preview .product-img-item img{
    object-fit: cover;
}
.store-product-preview .product-img-item {
    width: 118px;
    height: 107px;
}

.store-product-preview .product-img-table {
    row-gap: 21px;
}
.product-detail--price .actual-price{
font-size: 18px;
font-weight: 400;
opacity: 0.6;
line-height: normal;
color: #8D8D8D;
text-decoration: line-through;
}
.product-detail--warranty span{
    color:  #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    opacity: 0.6;
}
.product-detail--warranty p{
    color:  #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.product-detail--warranty h6{
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    margin: 0;
}
.product-detail--charges {
    color: #303030;
font-size: 16px;
font-weight: 500;
line-height: normal;
opacity: 0.6;
margin-bottom: 34px;
}
.store-home .slider--local-image{
    clip-path: none;
}
.store-home .mySwiper-story .swiper-button-prev ,.store-slider .mySwiper-story .swiper-button-prev {
    left: 15px;
}
.store-home .mySwiper-story .swiper-button-next ,.store-slider .mySwiper-story .swiper-button-next {
    right: 15px;
}
