.polls-item-head h6 {
  width: 86px;
  height: 25px;
  margin-bottom: 0;
}
.polls-item-head {
  margin-bottom: 10px;
}
.polls-item-head button {
  right: 0;
  left: unset;
  transform: translate(0, -50%);
}
.polls-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.polls-header p {
  height: 40px;
  width: calc(100% - 316px);
  margin: 0;
}
.calendar-clock::before{  
  transform: translate(17px,-100%) !important;
}

.polls-header div {
  height: 26px;
  width: 85px;
}

.polls-header h6 {
  height: 26px;
  width: 43px;
}
.polls-item h5 {
  height: 40px;
  width: 85px;
}
.polls-item-txt {
  width: calc(100% - 115px);
  height: 40px;
  opacity: 1;
  background: rgb(48 48 48 / 15%);
  display: flex;
  align-items: center;
}
.polls-item-txt p {
  border-radius: 15px;
  opacity: 0.2;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  height: 14px;
  width: 35%;
  margin-left: 20px;
  margin-bottom: 0;
}
.add-polls-item .polls-item {
  margin-bottom: 0;
  margin-top: 20px;
}

.content-manager-Polls-wrapper
  .polls-item.common-card-body:hover
  .image-buttons-cancel {
  display: flex;
}
.polls-item.common-card-body:hover .image-buttons-cancel {
  display: flex;
}
.font-hidden {
  color: transparent;
}
.like-tag {
  display: flex;
  gap: 10px;
  align-items: center;
}

.poll_like_bg {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.poll-dropdown .dropdown {
  width: 100% !important;
  border: none;
  background-color: #fafafa;
}
.poll-dropdown .dropdown.active .dropdown__items {
  background-color: #fafafa;
  outline: none;
}

.item-details-input
  .calender.poll_calender
  .calender
  .calendar
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input.custom-datepicker {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.expired-poll {
  color: #ff0000;
  font-size: 16px;
  font-weight: 400;
}

.active-poll {
  color: #4e4949;
  font-size: 16px;
  font-weight: 400;
}

.poll-option-btn {
  background-color: #FAFAFA;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-weight: 600;
  color: #0B0A0A;
}

.poll-option-btn:hover {
  background-color: #e0e0e0;
}

.poll-option-btn.selected {
  background: linear-gradient(
  134deg,
  rgba(0, 21, 151, 1) 3.03%,
  rgba(1, 16, 77, 1) 47.1%,
  rgba(2, 12, 28, 1) 80.14%,
  rgba(2, 11, 9, 1) 96.45%
);
  color: #fff;
}

.see_result_btn{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}