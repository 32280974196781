.add-discussions-item {
  border-radius: 15px;
  border: 1.8px solid #00298f5e;
  background: #fff;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disscussion-profile-img {
  width: 77px;
  height: 77px;
}
.disscussion-profile-txt {
  width: calc(100% - 92px);
}
.disscussion-profile-txt h2 {
  width: 100%;
  height: 20px;
  margin: 0;
}
.disscussion-profile-txt h3 {
  height: 15px;
  width: 50%;
  margin: 7px 0 15px 0;
}
.disscussion-profile-txt p {
  height: 21px;
  width: 90%;
  margin: 0;
}
.disscussion-item-profile-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.disscussion-item-other {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 50px;
}
.disscussion-group-member {
  width: 85px;
  height: 23px;
}
.disscussion-chat {
  width: 43px;
  height: 28px;
}
.content-manager-discussions-wrapper .discussions-item:hover .image-buttons-cancel {
  display: flex;
}
.discussions-item:hover .image-buttons-cancel {
  display: flex;
}


.comment-section-container {
  padding: 20px;
}

.add-item-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000004d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  scroll-behavior: smooth;
}

.add-item-content {
  /* padding: 20px; */
  max-height: 100%;
  overflow-y: auto; 
}

.scroll-bars {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.button_scroll_bar{
  padding-bottom: 40px !important;
  overflow: hidden;
}

.scroll-bars::-webkit-scrollbar {
  width: 8px;
}

.scroll-bars::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 4px;
}

.scroll-bars::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

h3 {
  margin-top: 0;
}

.comment-section {
  margin-top: 20px;
}

.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  max-height: 415px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #a5a4a4; 
}

.comment-item {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

.comment-header, .reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  margin-right: 10px;
}

.comment-text, .reply-text {
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 50px;
  word-wrap: break-word;
}

/* button {
  margin-right: 10px;
} */

.replies-list {
  list-style: none;
  padding-left: 20px;
  /* max-height: 200px;  */
  /* overflow-y: auto;  */
}

.reply-item {
  padding: 10px;
  position: relative;
}

.delete-reply {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-left: auto;
}

.add-reply {
  margin-top: 10px;
}

.add-comment {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.add-comment input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

/* .add-comment button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
} */
/* 
.add-comment button:hover {
  background-color: #0056b3;
} */

.item-details-input {
  /* margin-top: 20px; */
}

.button-row {
  text-align: right;
}

.secondary-btn-modal {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.secondary-btn-modal:hover {
  text-decoration: underline;
}

.comment-delete{
  border: none;
  background: transparent;
  color: #01104D;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
    right: 10px;
    top: 15px;
}
.comment-btns{
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding-left: 50px;
}
.comment-btns button{
  border: none;
  background: transparent;
  color: #01104D;
  font-size: 14px;
  font-weight: 500;
}
.edit-reply{
  padding-left: 50px;
  position: relative;
}
.edit-reply button{
  border: none;
  background: transparent;
  color: #01104D;
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
}
.add-reply{
  display: flex;
  align-items: center;
  position: relative;
}
.edit-reply input{
  border: none;
  border-bottom: 1px solid #001597;
  color: #001597;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  width: 100%;
  padding-right: 39px;
}
.add-item-contents{
  padding: 40px;
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.add-value{
  font-size: 0;
}

.like_giveaway{
  width: 24px !important;
  height: 24px !important;
}

.scroll-bars {
  max-height: 470px;
  overflow-y: auto;
  padding-right: 10px;
}

.scroll-bar {
  max-height: 405px;
  overflow-y: auto;
  padding-right: 10px;
}

.scroll-bar::-webkit-scrollbar {
  width: 8px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 4px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
.scroll-bars-no-scroll {
  padding: 0px 40px 40px 40px;
  /* max-height: 0; */
  overflow-y: auto;
  /* padding-right: 10px; */
}

.disscussion_description{
min-height: 150px !important
}

.tags_select_margin{
  margin-bottom: 0px;
}

.tag-edit-container{
  width: 80px;
}

.discussion-img-group img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}
.discussion-img-group .skeleton-img-group {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}

.discussion-img-group img:first-child {
  margin-left: 0;
}


.discussions-item {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.discussions-item.expanded {
  min-height: auto !important;
}

.discussion_topic{
  /* margin-bottom: 20px; */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* min-height: 40px; */
}
