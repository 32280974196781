.recent-podcast h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent-podcast h3 a {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #001597;
}

.recent-podcast-item {
  height: 269px;
  border-radius: 14.443px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  position: relative;
}

.recent-podcast-content {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 18px;
}

.recent-podcast-content .rating {
  right: 0;
  top: 0;
  position: absolute;
  border-radius: 0px 14.443px;
  z-index: 1;
}

.recent-podcast-item::after {
  content: "";
  /* background: linear-gradient(
    180deg,
    rgba(58, 58, 58, 0.4) 0%,
    rgba(58, 58, 58, 0) 54.42%,
    #161616 76.39%
  ); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14.443px;
}

.recent-podcast-item p {
  color: #fff;
  font-size: 17.331px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.podcast-item-profile {
  color: #303030;
  font-size: 13.48px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.podcast-item-profile img {
  border-radius: 8.666px;
  box-shadow: 0px 0px 3.226px 0px rgba(0, 0, 0, 0.15);
  width: 29px;
  height: 29px;
}

.podcast-item-detail {
  left: 20px;
  bottom: 10px;
  position: absolute;
  z-index: 1;
}
.podcast-item-detail h6 {
  color: #303030;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  margin-top: 3px;
  max-width: 282px;
  /* min-height: 33.6px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.podcast-item-detail .timer {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
  margin-bottom: 10px !important;
  color: #303030;
  min-height: 16px;
}
.timer {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
  margin-bottom: 10px !important;
  color: #303030;
  min-height: 16px;
}
.podcast-item-detail p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  margin-bottom: 10px !important;
  color: #303030;
  max-width: 282px;
  min-height: 36px;
  overflow: hidden;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}

.add-podcaest-video .podcast-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  border-radius: 15px;
  background: transparent;
}

.add-podcaest-video .podcast-cover {
  background: #e0e0e0;
  height: 269px;
  position: relative;
}

.add-podcaest-video .podcast-cover button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}

.add-podcaest-video .play {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #b4bab9;
}

.add-podcaest-video .duration-block {
  border-radius: 30px;
  background: #b4bab9;
  width: 43px;
  height: 15px;
  position: absolute;
  right: 15px;
  bottom: 14px;
}

.add-podcaest-video .podcast-detail h6 {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  width: 64px;
  height: 21px;
  margin-bottom: 10px;
}

.add-podcaest-video .rating-block {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  width: 39px;
  height: 21px;
}

.add-podcaest-video .podcast-name {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  min-height: 17px !important;
  margin: 6px 0px;
}

.add-podcaest-video .podcast-upload-time {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  width: 89px;
  height: 16px;
  display: inline-block;
}

.add-podcaest-video .other-info {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  height: 34px;
  margin-top: 10px;
}
.Podcasts-item {
  grid-template-columns: repeat(auto-fit, minmax(0, 322px)) !important;
}
.Podcasts-item-details {
  grid-template-columns: repeat(auto-fit, minmax(0, 350px)) !important;
  display: grid;
  gap: 30px;
}

.Podcasts-item .awards-item:hover .image-buttons {
  display: flex;
}
.awards-item:hover .image-buttons {
  display: flex;
}
.minutes-badge {
  width: 23%;
  height: 35px;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 15px 0 0 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.63px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.awards-item-podcast {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  /* height: 424px; */
  height: fit-content;
  width: 322px;
}
.awards-item-podcast:hover .image-buttons-cancel {
  display: flex;
}
.rating {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  width: 37px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
}
.rating img {
  margin-left: 0;
}
.video-details {
  padding: 12px;
}
.video-details p {
  min-height: 0;
  overflow: auto;
  flex: 1;
}
.rating span {
  width: auto;
}
.user_image {
  padding: 20px 20px 0px 20px;
}
.user_image img {
  border-radius: 5px;
}

.user_images .podcast_user_name {
  -webkit-text-fill-color: #1a1a1a !important;
  -webkit-opacity: 0.9 !important;
}

@media screen and (max-width: 1400px) {
  .Podcasts-item {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .awards-item-podcast {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    /* height: 424px; */
    height: fit-content;
    width: 100%;
  }
}
@media screen and (max-width: 1067px) {
  .Podcasts-item {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .awards-item-podcast {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    /* height: 424px; */
    height: fit-content;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .Podcasts-item {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .awards-item-podcast {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    /* height: 424px; */
    height: fit-content;
    width: 100%;
  }
  .user_images h6 .skel-podcast {
    max-width: 65px;
  }
}
.volume-icon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
}
.volume-icon-details {
  position: absolute;
  bottom: 16px;
  left: 40px;
  cursor: pointer;
  z-index: 2;
}

.podcast_text {
  text-align: center;
  height: 100%;
  width: 100%;
  margin-top: 40%;
  margin-bottom: 0px !important;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  line-height: normal;
}
.play_button_skeleton {
  background: none !important;
}

.play_time_blank {
  display: flex;
  justify-content: space-between;
}
.play_time_blank .play {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #b4bab9;
}
.play_time_blank .time {
  width: 43px;
  height: 15px;
  border-radius: 30px;
  background: #b4bab9;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
}

.bg_play_pause {
  width: 34px;
  height: 33px;
  background-color: #fff;
  border-radius: 50%;
}

.podcast-detail .play_pause_icon {
  width: 34px;
  height: 33px;
}

.designation_color {
  color: #fff !important;
  opacity: 1 !important;
  font-weight: 400 !important;
}
.podcast_profile_user_name {
  font-size: 14px !important;
}

.see-more {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #01104d;
  cursor: pointer;
  margin-left: 4px;
}

.podcast-item-detail {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 12px 10px 10px 10px;
}

.podcast-item-detail.expanded {
  display: block;
  height: auto;
  overflow: visible;
}

.podcast-dropdown .dropdown {
  width: 100% !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.dropdown.active.giveaway-dropdown .dropdown__items {
  outline: 1px solid rgba(225, 226, 229, 1) !important;
}

.awards-item-podcast:hover .play_icons {
  display: block !important;
}
.play_icons {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 34%;
  right: 41%;
  height: 50px;
  width: 50px;
}
