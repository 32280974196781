.add-item-model-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  scroll-behavior: smooth;
}

.add-item-model-edit .add-item-content-edit {
  background: var(--primary-bg);
  border-radius: 15px;
  /* padding: 40px; */
  width: 95%;
  height: 95%;
  overflow-y: scroll;
  /* padding-right: 10px; */
}

.add-item-model-edit .add-item-content-edit::-webkit-scrollbar {
  display: none;
}

.tabs .details-tab-view .react-tabs__tab-list {
  border-radius: 47px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  background: #FAFAFA;
  box-shadow: none;
}

.details-tab-view .react-tabs__tab {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 11px;
  flex: 1 1;
}

.react-tabs__tab .component_icon {
  width: 24px;
  height: 24px;
  filter: brightness(140%);
}

.submenu .react-tabs__tab .component_icon {
  width: 20px;
  height: 20px;
  filter: brightness(80%);
}

/* .details-tab-view .submenu .react-tabs__tab--selected img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(52%) saturate(6444%) hue-rotate(229deg) brightness(88%) contrast(115%);
} */

.details-tab-view .submenu .react-tabs__tab--selected img {
  filter: brightness(0) saturate(100%) invert(8%) sepia(59%) saturate(6458%) hue-rotate(229deg) brightness(81%) contrast(115%) !important;
}

.tabs .details-tab-view .submenu .react-tabs__tab-list {
  border-radius: 47px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  background: transparent;
  box-shadow: none;
}

.details-tab-view .submenu .react-tabs__tab {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 11px;
  flex: 1 1;
}

.details-tab-view .submenu .react-tabs__tab--selected {
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--border) !important;
  border-radius: 0px;
}


.tabs .details-tab-view.profile_builder .react-tabs__tab-list {
  border: none !important;
}
.headername {
  position: sticky;
  top: 0;
  transition: all 0.2s ease;
}
.profileimage {
  max-width: 150px;
  max-height: 150px;
  transition: all 0.2s ease;
}
.headername .profileimage {
  max-width: 40px;
  max-height: 40px;
  transition: all 0.2s ease;
}
.headername .nocontent {
  max-height: 0;
  max-width: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  margin-top: 0px;
}
.nocontent {
  margin-top: 20px;
}
.headernamet {
  position: sticky;
  top: 0;
  transition: all 3s ease;
  max-height: 1000px;
  max-width: 100%;
}