.grid_add_text {
  text-align: center;
  background: #E4E4E480;
  border: 0.5px dashed #bab8b8;
  border-radius: 15px;
  height: 377px;
  max-height: 377px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photos-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 15px; 
}

.photos-grid-item {
  width: 238px; 
  height: 238px !important; 
  position: relative;
  border-radius: 5px;
}

.grid-image {
  width: 238px; 
  height: 238px;
  object-fit: cover;
  border-radius: 8px; 
}
